import * as React from 'react'
import { useEffect, useState } from 'react'

import { Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'

import ArrowIcon from 'svgs/arrow_top_right.svg'
import FlagIcon from 'svgs/flag.svg'


// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import axios from 'axios'
import { config } from '../config'
import Api from '../helpers/api'
import Loading from '../components/shared/loading'

const Dashboard = () => {

  const [user, setUser] = useState(null)

  const [student, setStudent] = useState(null)

  const [studentObjectives, setStudentObjectives] = useState([])
  const [teamObjectives, setTeamObjectives] = useState(null)
  const [efficiency, setEfficiency] = useState({ done: 0, not_done: 0 })
  const [teamStudents, setTeamStudents] = useState([])

  const [weeks, setWeeks] = useState([]);
  const [biWeeks, setBiWeeks] = useState([]);

  const CURRENT_WEEK = 0
  const CURRENT_BI_WEEK = 0

  const selectedObjective = studentObjectives.find(objective =>  objective.order === CURRENT_WEEK+1)


  useEffect(() => {
    if (!user) {
      return
    }
    Api.getInstance().getStudents().then(response => {
      setStudent(response.find(s => {
        return s.email === user.email;
      }))
    }).catch(e => {

    }).finally(() => {
    })

  }, [user])

  useEffect(() => {
    if (!student) {
      return
    }
    Api.getInstance().getStudentObjectives(student.id).then(response => {
      let eff = { done: 0, not_done: 0 }
      response.map(objective => {
        objective.keyResults.map(keyResult => {
          if (keyResult.done) {
            eff.done++
          } else {
            eff.not_done++
          }
        })
      })
      setEfficiency({ ...eff })
      setStudentObjectives(response)
    })
    Api.getInstance().getTeamObjectives(student.teamId).then(response => {
      setTeamObjectives(response)
    })
    Api.getInstance().getTeamStudents(student.teamId).then(response => {
      setTeamStudents(response)
    })
  }, [student])


  useEffect(()=>{
    if(!teamObjectives){
      return;
    }
    let w = [];
    let bw = [];
    teamObjectives.keyResults.map(({bi_weekly})=>{

      bi_weekly.map((bwl)=>{
        bw.push(bwl)
        bwl.week.map(i=>{
          w.push(i);
        })
      })
    });
    setWeeks(w)
    setBiWeeks(bw);
  },[teamObjectives])

  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      setUser(user)
      if (user.role !== 'ROLE_STUDENT') {
        throw new Error('Only Students can this page')
      }
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/'
    }
  }, [])

  useEffect(() => {
  }, [efficiency])

  const progressBarColors = [400, 500, 600, 700, 800, 900];

  const getKeyResultProgress = (keyResult)=>{
    let total = 0;
    let done = 0;
    keyResult.bi_weekly.map(bi_weekly=> {
      bi_weekly.week.map(week=>{
        done += week.keyResult.filter(({done}) => done).length;
        total += week.keyResult.length;
      })
    })
    return (done/total*100);
  }

  return <Layout title='Team'>
    <Container className='pb-5'>
      <link
        href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
        rel='stylesheet'
        integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
        crossOrigin='anonymous'
      ></link>

      <Row className='mt-md-5 mb-md-5 my-3 align-items-center'>
        <Col>
          <h3 className='mt-0 fw-medium'>My Profile</h3>
        </Col>
        <Col md='auto' className='mb-3 mt-md-0 mt-3 mb-md-0 text-md-end'>
          <a href='/test' className='text-dark me-4'>Career Assessment <ArrowIcon className='ms-2' /></a>
        </Col>
      </Row>
      {!student &&
      <div>
        <Loading/>
      </div>
      }
      {student &&
      <>
        <Row className='shadow-up-xl rounded-up-xl mx-0'>
          <Col lg='8' className='p-4 shadow-down-xl rounded-down-xl mt-lg-0 mt-3'>
            <h4>My Challenge</h4>
            <p>{user.challenge?.data?.problem_summary}</p>
            <a href={`/challenge/${user.challenge?.id}`} className='text-dark'>Challenge Page <ArrowIcon className='ms-2' /></a>
          </Col>
          {/*<Col lg='4' className='border-start border-end p-4 shadow-down-xl rounded-down-xl mt-lg-0 mt-3'>
            <h4>
              My Tasks (Trello)
            </h4>
            <div className='max-h-226px overflow-y-auto'>
              <Table responsive className='mb-0'>
                <tbody className='last-has-no-border'>
                {[...Array(10)].map((x, i) => (
                  <tr key={i}>
                    <td>Due date</td>
                    <td>Task 1</td>
                    <td>Done</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </div>

          </Col>*/}
          <Col lg='4' className='border-start p-4 shadow-down-xl rounded-down-xl mt-lg-0 mt-3'>
            <h4>My Performance</h4>
            <div className='progress-wrapper mx-auto'>
              <ProgressBar now={parseInt(efficiency.done / (efficiency.done + efficiency.not_done) * 100)} />
              <div className='progress-info text-center fs-20px'>{efficiency.done + efficiency.not_done} <br /> <span
                className='fs-12px'>Total</span></div>
            </div>
            <div className='text-center fs-12px fw-medium d-flex align-items-center justify-content-center'>
              <div className='d-flex align-items-center'>
                <div className='complete-dot me-2'></div>
                Complete
              </div>
              <div className='d-flex align-items-center ms-4'>
                <div className='incopmlete-dot me-2'></div>
                Incomplete
              </div>
            </div>
          </Col>
        </Row>


        <Row className='mt-3'>
          <Col lg='7'>
            <div className='shadow-xl rounded-xl p-4'>
              {selectedObjective &&
              <Card className='border-top-0 border-end-0 border-start-0 rounded-0'>
                <h4>Weekly Objective</h4>
                <p>Objective: {selectedObjective.keyResultTitle}</p>
                <ul className='last-has-no-border ps-0 ps-md-5'>
                  {selectedObjective.keyResults.map(keyResult => {
                    return <li className='d-flex justify-content-between align-items-center border-bottom py-3'>
                      <div>
                        <p className='mb-2'>
                          <FlagIcon className='me-2' />
                          {keyResult.title}
                        </p>
                        {keyResult.description}
                      </div>
                      {keyResult.done ? <Button className='btn btn-secondary btn-sm px-4 py-2 px-5'>Done</Button>
                        :
                        <Button className='btn btn-secondary incomplete btn-sm px-4 py-2 px-5'>Incomplete</Button>}
                    </li>
                  })}
                </ul>
              </Card>
              }
              <Card className='border-0 mt-3 mt-md-4'>
                <h4>Bi-weekly Goals</h4>
                {teamObjectives && biWeeks[CURRENT_BI_WEEK] &&
                <>
                  <p>Objective: {biWeeks[CURRENT_BI_WEEK].title}</p>
                  <ul className='last-has-no-border ps-0 ps-md-5'>
                    {biWeeks[CURRENT_BI_WEEK].week.map(week => {
                        return <li className='d-flex justify-content-between align-items-center border-bottom py-3'>
                          <div>
                            <p className='mb-2'>
                              <FlagIcon className='me-2' />
                              {week.title}
                            </p>
                            {week.description}
                          </div>
                          {week.done ? <Button className='btn btn-secondary btn-sm px-4 py-2 px-5'>Done</Button>
                            :
                            <Button className='btn btn-secondary incomplete btn-sm px-4 py-2 px-5'>Incomplete</Button>}
                        </li>
                      })

                    }
                  </ul>
                </>
                }
              </Card>
            </div>
          </Col>
          <Col lg='5' className='mt-lg-0 mt-3'>
            <div className='shadow-xl rounded-xl p-4 h-100'>
              <Card className='border-top-0 border-end-0 border-start-0 rounded-0'>
                <h4>Team Performance</h4>
                <div className='d-flex mb-lg-5 mb-3'>
                  <div className='w-100 h-30px bg-blue-900'></div>
                  <div className='w-100 h-30px bg-blue-800'></div>
                  <div className='w-100 h-30px bg-blue-700'></div>
                  <div className='w-100 h-30px bg-blue-600'></div>
                  <div className='w-100 h-30px bg-blue-500'></div>
                  <div className='w-100 h-30px bg-blue-400'></div>
                </div>

                <Row className='mb-4'>
                  {teamStudents.map((student, index) => {
                    return <Col xs='6' className='mt-3'>
                      <h6>{student.fullName}</h6>
                      <ProgressBar variant={`blue-${progressBarColors[index]}`} className='rounded-0 h-30px'
                                   now={parseInt(student.progress)} />
                    </Col>
                  })}
                </Row>

              </Card>
              <Card className='mt-3 border-0'>
                <h4>Product Performance</h4>
                <div className='d-flex mb-lg-5 mb-3'>
                  <div className='w-100 h-30px bg-blue-900'></div>
                  <div className='w-100 h-30px bg-blue-800'></div>
                  <div className='w-100 h-30px bg-blue-700'></div>
                  <div className='w-100 h-30px bg-blue-600'></div>
                </div>
                {teamObjectives &&
                <Row className='mb-4'>
                  {teamObjectives.keyResults.map((keyResult, index) => {
                    return <Col xs='6' className='mt-3'>
                      <h6>{keyResult.title}</h6>
                      <ProgressBar variant={`blue-${progressBarColors[index+2]}`} className='rounded-0 h-30px' now={getKeyResultProgress(keyResult)} />
                    </Col>
                  })}
                </Row>
                }
              </Card>

            </div>
          </Col>
        </Row>
      </>
      }
    </Container>
  </Layout>
}

export default Dashboard
